
/* Général */
$body_background_color : #fff;
$body_texte_color: #222; 
$link_color : inherit; 
$link_hover_color : #666;

$titlesection_color : #fff;
$titlesection_background_color : #222;

/* Header */
$header_background_color : #222;
$slogan_color : #fff;

$nav_color : #fff;
$nav_background_color :#222;
/*$nav_border_color : #ccc;*/
$sousnav_color: #222;
$sousnav_background_color : initial;
$sousnav_hover_color : #fff;
$sousnav_hover_background_color : #222;
$nav_actif_color : #222; 
$nav_actif_background_color : #fff;

/* Content */
$content_background_color : #fff;
$navarianne_borderbottom_hover_color : #222;
$navarianne_actif_color :#666;
$button_background_color :#222;
$button_color : #fff;
$button_border_color: #222;
$button_hover_color : #222;
$button_hover_background_color : #fff;

$formperso_titre_color: #222;


/* accueil */
$slidetext_color: #000;
$slidetext_background_color : #fff;


/* boutique */
$etiquette_color :#fff;
$etiquette_background_color : #222;
$produits_mask_color : #fff;
$produits_mask_background_color : rgba(34, 34, 34,0.6);
$produits_zoom_color : #fff;
$produits_zoom_background_color : $produits_mask_background_color;
$produits_zoom_hover_color :#222;
$produits_zoom_hover_background_color : #fff;
$produits_nomprod_background_color: #777;
$produits_nomprod_color: #fff !important;
$oldprix_color : #777;
$prix_color: #222;

$ficheproduit_remise_background_color: #222;
$ficheproduit_oldprix_color: #222;
$ficheproduit_fleches_color: #222;

$searchbaricon_color : #fff;
$searchbaricon_background_color : #222;


/* galerie */
$photo_mask_background_color : rgb(34, 34, 34);
$photo_description_color : #fff;
$photo_description_background_color : transparent;
$photo_commentaires_color : #fff;

/* livre d'or */
$msglo_texte_color: #000; 
$msglo_h4_color: #fff;
$msglo_h4_background_color: #222;
$msglo_note_color: #fff;

/* contact */
$form_focus_shadow: 0 0 5px #222;
$form_focus_bordercolor: #222;


/* Footer */
$footer_color : #fff;
$footer_background_color : rgb(34, 34, 34); 

/* Blog */
$background_blog_menu :  #222;
$background_blog_h2 : #222;
$white : #fff !important;

/* Template Panier */
$panier-background-color:#222;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

//@import "design/variables";
@import "design/758series/declinaison";